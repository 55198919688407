import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { parseQueryString } from '../utils/helper'
import { DESTINATIONS } from '../utils/constants'
import DestinationHeader from '../component/page_component/DestinationHeader'
import { Helmet } from 'react-helmet'
import hreflangConfig from '../utils/hreflangConfig'

parseQueryString
const Buy: React.FC<PageProps> = (props: any) => {
  const {
    location,
    pageContext: { data },
  } = props
  const region = location?.pathname.split('/')[2]
  const intl = useIntl()

  let destinationHeaderProps = {
    src: '',
    alt: '',
    title: '',
    detail: '',
    btnTxt: '',
    tags: '',
  }

  if (DESTINATIONS[region]) {
    destinationHeaderProps = {
      src: DESTINATIONS[region].img,
      alt: DESTINATIONS[region].alt,
      title: intl.formatMessage({
        id: `dreamDestination.${DESTINATIONS[region].translation}.title`,
      }),
      detail: intl.formatMessage({
        id: `dreamDestination.${DESTINATIONS[region].translation}.detail`,
      }),
      btnTxt: intl.formatMessage({ id: DESTINATIONS[region].btnTxt }),
      tags: intl.formatMessage({
        id: `dreamDestination.${DESTINATIONS[region].translation}.subtitle`,
      }),
    }
  }

  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`

  const valuesToCheck = [
    'paris-je-taime',
    'normandie-cherie',
    'loccitanie-terre-de-soleil',
    'la-cote-atlantique',
    'mediterranee-mon-amour',
    'a-la-montagne',
    'beaute-des-iles',
    'le-nouveau-portugal',
    'london-calling',
    'big-in-usa',
    'dubai',
  ]

  let foundValue = ''

  valuesToCheck.forEach((value) => {
    if (canonicalUrl.includes(value)) {
      foundValue = value
    }
  })

  const hreflangLinks = foundValue
    ? hreflangConfig[`/fr/${foundValue}/` as keyof typeof hreflangConfig]
    : {}
  return (
    <Layout
      location={location}
      showFlotingMenu={false}
      title={destinationHeaderProps.title + ' - Kretz'}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          foundValue &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
           <meta name="description" content="Destinations de Rêve - Kretz" />
      </Helmet>
      <DestinationHeader {...destinationHeaderProps} />
      <Grid container justifyContent="center" id="buy-region-dream-destination">
        <PropertyList
          bgClr="#E0E5DE"
          filterBackgroundColor={'#fff'}
          data={data}
          location={location}
        />
      </Grid>
    </Layout>
  )
}

export default Buy
