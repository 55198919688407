import React from 'react'
import { Hidden, makeStyles } from '@material-ui/core'
import { KretzIconCircle } from '../../icons/KretzIconCircle'
import { Image } from '../Images'
import ArrowSvg from '../../icons/arrow.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E0E5DE',
    width: '100%',
  },
  wrapper: {
    marginTop: '50px',
    padding: '110px 1rem 0',
    textAlign: 'center',
    position: 'relative',
    maxWidth: 1200,
    margin: '0 1rem',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
    },
  },
  img: {
    [theme.breakpoints.up('md')]: {
      marginTop: '2rem',
    },
  },
  textContainer: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginRight: '4rem',
    },
  },
  logo: {
    width: '60px',
    height: 'auto',
    margin: 'auto',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: '97%',
      bottom: '6rem',
      width: '100px',
      zIndex: 1,
    },
  },
  btn: {
    textAlign: 'center',
    margin: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
  },
  btnTxt: {
    marginBottom: '1.5rem',
    fontSize: '.7rem',
  },
  tags: {
    fontSize: '.8rem',
    marginTop: '2rem',
    lineHeight: 1,
  },
  title: {
    fontFamily: "'Cormorant Garamond', serif",
    fontWeight: 500,
    fontSize: '3rem',
    marginTop: 0,
    lineHeight: 1,
    marginBottom: 1,
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      marginTop: '2rem',
      fontSize: '4rem',
    },
  },
  detail: {
    fontFamily: "'Cormorant Garamond', serif",
    margin: '2rem 0',
    fontSize: '1.2rem',
    lineHeight: 1.2,
  },
}));

type PropsType = {
  src: string
  alt: string
  title: string
  detail: string
  btnTxt: string
  tags: string
};

const DestinationHeader = ({ src, alt, title, detail, btnTxt, tags }: PropsType) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.textContainer}>
          <KretzIconCircle className={classes.logo} />
          <p className={classes.tags}>{tags}</p>
          <h3 className={classes.title}>{title}</h3>
          <Hidden mdUp>
            <Image src={src} alt={alt} layout="CONSTRAINED" className={classes.img} />
          </Hidden>
          <p className={classes.detail}>{detail}</p>
          <p className={classes.btn}>
            <span className={classes.btnTxt}>{btnTxt}</span>
            <span>
              <img src={ArrowSvg} alt="arrow" loading="lazy" />
            </span>
          </p>
        </div>
        <Hidden smDown>
          <Image src={src} alt={alt} layout="CONSTRAINED" className={classes.img} />
        </Hidden>
      </div>
    </div>
  )
}

export default DestinationHeader
